<template>
	<div class="zdy_search">
		<el-form :inline="true" :model="search_data">
			<el-form-item label="订单号"><el-input v-model="search_data.uuid" size="mini" placeholder="请输入订单号" /></el-form-item>
			<el-form-item label="收货人"><el-input v-model="search_data.address_name" size="mini" placeholder="请输入收货人" /></el-form-item>
			<el-form-item label="订单状态">
				<el-select v-model="search_data.is_finish"  class="m-2 single"  clearable  placeholder="请选择是否完成" size="mini">
				    <el-option 
				      v-for="item in is_finish"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value"
				    />
				  </el-select>
			</el-form-item>
			<el-form-item><el-button type="primary" size="mini" @click="onSearch">搜索</el-button></el-form-item>
		</el-form>
		<div class="hr"></div>
		<div class="use_btn"><!-- <el-button size="mini" type="primary" @click="add('')">新增</el-button> --></div>
	</div>
	<div class="zdy_table">
		<!-- <div style="background: white;padding: 10px;width: 95%;margin-left: 2.5%;">数据</div> -->
		<el-table :data="data"  border style="width: 95%;margin-left: 2.5%;" v-loading="loading">
			<el-table-column prop="uuid" label="订单号" />
			<el-table-column prop="user_name" label="微信用户名" />
			<el-table-column prop="address_name" label="收货人" />
			<el-table-column prop="address_mobile" label="联系电话" />
			<el-table-column prop="address" label="收货地址" />
			<el-table-column prop="price" label="金额" />
			<el-table-column prop="create_time" label="下单时间" />
			<el-table-column prop="is_finish_str" label="订单状态">
				<template #default="scope">
					<el-button link :type="scope.row.is_finish == '1' ? 'success' : 'danger'" size="mini">
						{{ scope.row.is_finish_str }}
					</el-button>
				</template>
			</el-table-column>

			<!-- <el-table-column prop="stock" label="金额" /> -->
			<el-table-column fixed="right" label="操作" >
				<template #default="scope">
					<el-button link type="" size="mini" @click="detail(scope.row.uuid)">
						<!-- <el-icon><EditPen /></el-icon> -->
						详情
					</el-button>
					<br/>
					<el-popconfirm style="margin-top: 5px;" title="是否修改订单状态？" @confirm="updateStataus(scope.row.uuid)" confirm-button-text="是" cancel-button-text="否">
						<template #reference>
							<el-button link type="primary" size="mini">
								修改状态
							</el-button>
						</template>
					</el-popconfirm>
					<br/>
					<el-popconfirm style="margin-top: 5px;" title="是否删除该订单？" @confirm="del(scope.row)" confirm-button-text="是" cancel-button-text="否">
						<template #reference>
							<el-button link type="danger" size="mini">
								<!-- <el-icon><DeleteFilled /></el-icon> -->
								删除订单
							</el-button>
						</template>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
	</div>
	<div class="zdy_page"><page-commom :search_data="search_data" @page_search="pageSearch"></page-commom></div>
	<el-drawer v-model="show" @close="close" direction="rtl" :with-header="false" size="80%">
		<order-dialog v-if="show" :uuid="uuid" @close="close"></order-dialog>
	</el-drawer>
	<!-- <order-dialog v-if="show" :uuid="uuid" @close="close"></order-dialog> -->
</template>

<script setup>
import { ref, reactive, toRef, inject, onMounted } from 'vue';
import orderDialog from './orderDialog.vue';
import pageCommom from '@/components/commom/pageCommom';
onMounted(() => {
	onSearch();
	getClassify();
});
const show = ref(false);
const $get = inject('$get');
const $success = inject('$success');
const $error = inject('$error');
const loading = ref(false);
const classify = ref({});
const is_finish=ref({});
const getClassify = async () => {
	const res = await $get('/Code/GetCode', { code: 'classify,is_finish' });
	classify.value = res.result.classify;
	is_finish.value=res.result.is_finish
};
let uuid = ref('');
const search_data = ref({
	name: '',
	page: 1,
	limit: 10,
	total: 0
});
var data = ref([]);
const pageSearch = data => {
	search_data.value = data.value;
	onSearch();
};
const detail = val => {
	uuid.value = val;
	show.value = !show.value;
};
const onSearch = async () => {
	loading.value = true;
	const res = await $get('/api/Order/GetList', search_data.value);
	loading.value = false;
	search_data.value.total = res.result.total;
	data.value = res.result.data;
};
const updateStataus = async val => {
	const res = await $get('/api/Order/UpdateStatus', { uuid: val });
	if (res.errCode == '0') $error(res.msg);
	$success(res.msg);
	onSearch();
};
const del=async(row)=>{
	if(row.is_finish=="1"){
		$success("该订单已完成，无法删除");return;
	}
	const res = await $get('/api/Order/Delete', { uuid: row.uuid });
	if (res.errCode == '0') $error(res.msg);
	$success(res.msg);
	onSearch();
}
const close = () => {
	show.value = !show.value;
	onSearch();
};
</script>

<style scoped>
.goods_img {
	width: 70px;
	height: 70px;
}
.flex {
	display: flex;
}
.hr {
	border-bottom: 1px solid #edf0f6;
}
.use_btn {
	margin-top: 10px;
}
.zdy_search {
	background: white;
	overflow: hidden;
	padding: 10px;
	border: 1px solid #edf0f6;
}
.zdy_table {
	margin-top: 10px;
}
.zdy_page {
	padding: 10px;
	width: 95%;
	margin-left: 2.5%;
	text-align: right;
}
</style>
